import React from "react";
import styled, { css } from "styled-components";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StyledContainer = styled(ToastContainer)`
  ${({ theme }) => css`
    --toastify-color-light: ${theme.themeColors.white};
    --toastify-color-dark: ${theme.themeColors.dark};
    --toastify-color-info: ${theme.themeColors.blue5};
    --toastify-color-success: ${theme.themeColors.success};
    --toastify-color-warning: ${theme.themeColors.warning};
    --toastify-color-error: ${theme.themeColors.danger};

    /* .toast is passed to toastClassName */
    .toast {
    }
  `}

  /* .toast-container */
  .Toastify__toast {
    min-height: 0;
    padding: 0;
    border-radius: 8px;
    z-index: 1001;
  }

  .Toastify__close-button[aria-label="close"] {
    display: none;
  }

  /* .body is passed to bodyClassName */
  .Toastify__toast-body {
    padding: 0.8rem;
  }

  /* .progress is passed to progressClassName */
  .Toastify__toast-progress {
  }
`;

function StyledToastContainer() {
  return <StyledContainer transition={Slide} />;
}

export default StyledToastContainer;

import { parseCookies, setCookie, destroyCookie } from "nookies";
import { multipartFileInterceptor } from "lib/form-data-middleware";
import currentUrl from "lib/current-url";
import { BASE_API_URL } from "lib/constants";
import ApiClient from "devise-token-auth-client";

export default function initAuthClient({ ctx, base, userType, store }) {
  const sessionConfig = ctx?.req
    ? {
        redirect: (url) => {
          ctx?.res.writeHead(301, { location: url });
          ctx?.res.end();
        },
        clearResponseCookie: (key) => destroyCookie(ctx, key, { path: "/" }),
        setResponseCookie: (key, val) =>
          setCookie(ctx, key, JSON.stringify(val), { path: "/" }),
        requestCookies: parseCookies(ctx),
      }
    : {
        redirect: (url) => window.location.replace(url),
      };

  const sharedConfig = {
    ...sessionConfig,
    location: currentUrl(ctx),
    getState: store.getState,
    dispatch: store.dispatch,
    baseURL: BASE_API_URL,
  };

  const client = new ApiClient({
    ...sharedConfig,
    endpointConfig: { base },
    userType,
  });

  client.interceptors.request.use(multipartFileInterceptor);

  return client;
}

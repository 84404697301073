// Base configuration for all themes. Each theme will
// subsequently override these styles.

export const spacing = ({ defaults }) => ({
  ...defaults,
  6: "4rem",
  7: "8rem",
});

const baseStyles = {
  xs: {
    ul: {
      mt: spacing[0],
      mb: "0",
      pl: spacing[4],
    },
    a: {
      color: "#1D1D1F",
    },
    h1: {
      size: "2.635rem",
      weight: 600,
    },
    h2: {
      size: "2rem",
      weight: 600,
    },
    h3: {
      size: "1.5rem",
      weight: 600,
    },
    h4: {
      size: "1.125rem",
      weight: 600,
    },
    h5: {
      size: "1rem",
    },
    h6: {
      size: "0.875rem",
    },
    large: {
      lineHeight: 1.1875,
      size: "1rem",
    },
    p: {
      weight: 400,
      size: ".875rem",
      margin: 0,
    },
    small: {
      lineHeight: 1.5,
      size: "0.75rem",
    },
    button: {
      size: ".875rem",
      weight: 600,
    },
  },
  lg: {
    h1: {
      lineHeight: 1.104,
      size: "4rem",
    },
    h2: {
      size: "3.375rem",
    },
  },
};

export default baseStyles;

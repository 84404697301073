import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getCurrentLocation } from "store/location/selectors";
import { UPDATE_PHYSICAL_ADDRESS } from "store/location";
import findLocationByIp from "lib/find-location-by-ip";
import { isEmpty } from "lodash";

export function useLocationChange() {
  const dispatch = useDispatch();
  return (payload) => {
    dispatch({ type: UPDATE_PHYSICAL_ADDRESS, payload });
  };
}

export default function useCurrentLocation() {
  return useSelector(getCurrentLocation, shallowEqual);
}

export function useFallbackLocation() {
  return {
    latitude: "39.9525839",
    longitude: "-75.1652215",
    formatted_address: "Philadelphia, PA",
    street_address: "Philadelphia, PA",
  };
}

export async function setLocationFromIP(ipAddress, store) {
  const currentLocation = getCurrentLocation(store);

  if (isEmpty(currentLocation)) {
    const payload = await findLocationByIp(ipAddress);
    store.dispatch({ type: UPDATE_PHYSICAL_ADDRESS, payload });
  }
}

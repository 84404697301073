import getConfig from "next/config";

export const {
  publicRuntimeConfig: {
    API_HOST,
    API_PORT,
    API_PROTOCOL,
    CLIENT_HOST,
    CLIENT_PORT,
    CLIENT_PROTOCOL,
    CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    CONTENTFUL_PUBLIC_ACCESS_TOKEN,
    CONTENTFUL_SPACE_ID,
    GOOGLE_MAPS_API_KEY,
    GTM_ID,
    IP2LOCATION_API_KEY,
    OKTA_DOMAIN,
    OKTA_KEY,
    RECAPTCHA_SITE_KEY,
    STRIPE_PUBLIC_KEY,
    CONTENTFUL_ENVIRONMENT,
    MAXMIND_ACCOUNT_NO,
    MAXMIND_LICENSE_NO,
    DEV_OKTA_BYPASS,
    WILDEST_CONTENTFUL_SPACE_ID,
    WILDEST_CONTENTFUL_PUBLIC_ACCESS_TOKEN,
    WILDEST_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
    WILDEST_CONTENTFUL_ENVIRONMENT,
  },
} = getConfig();

export const toastSettings = {
  autoClose: 2500,
  theme: "colored",
  hideProgressBar: true,
  icon: false,
};

function getBaseUrl({ protocol, host, port }) {
  let url = `${protocol}://${host}`;
  if (port) {
    url += `:${port}`;
  }

  return url;
}

function getBaseApiUrl() {
  return getBaseUrl({
    protocol: API_PROTOCOL,
    host: API_HOST,
    port: API_PORT,
  });
}

function getBaseClientUrl() {
  return getBaseUrl({
    protocol: CLIENT_PROTOCOL,
    host: CLIENT_HOST,
    port: CLIENT_PORT,
  });
}

export const TIPPING_WINDOW = 28;
export const ORDER_MINIMUM = 100;
export const STRIPE_PADDING = 30;
export const GOODFRIEND_FEE = 0.05;
export const STRIPE_MULTIPLIER = 0.029;
export const BASE_API_URL = getBaseApiUrl();
export const NOTES_TEXTAREA_CHAR_LIMIT = 500;
export const BASE_CLIENT_URL = getBaseClientUrl();

import React, { createContext, useMemo } from "react";
import { ApiProvider } from "api-saga";
import PropTypes from "prop-types";

export const QueryContext = createContext({
  readEndpoint: undefined,
  writeEndpoint: undefined,
});

export function QueryProvider({
  store,
  clients,
  children,
  readEndpoint,
  writeEndpoint,
  defaultUserType,
}) {

  const queryProviderValue = useMemo(() => ({ readEndpoint, writeEndpoint, clients }), [readEndpoint, writeEndpoint, clients]);
  return (
    <ApiProvider
      store={store}
      clients={clients}
      reducerKey="queryApi"
      defaultUserType={defaultUserType}
    >
      <QueryContext.Provider value={queryProviderValue}>
        {children}
      </QueryContext.Provider>
    </ApiProvider>
  );
}

QueryProvider.propTypes = {
  store: PropTypes.shape({
    getState: PropTypes.func,
    dispatch: PropTypes.func,
  }).isRequired,
  // eslint-disable-next-line
  clients: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  defaultUserType: PropTypes.string.isRequired,
  readEndpoint: PropTypes.string,
  writeEndpoint: PropTypes.string,
};

QueryProvider.defaultProps = {
  readEndpoint: "/deep-unrest/read",
  writeEndpoint: "/deep-unrest/write",
};

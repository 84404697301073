import React, {
  Suspense,
  useMemo,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import PropTypes from "prop-types";
import arrayMutators from "final-form-arrays";
import { v4 as uuid } from "uuid";
import { Form } from "react-final-form";
import {
  mergeWith,
  find,
  pick,
  isEmpty,
  isNumber,
  values as objValues,
  get,
  has,
  reduce,
  each,
  set,
  includes,
  isObject,
  isFunction,
  isEqualWith,
  isArray,
  cloneDeepWith,
  merge,
  reject,
  unset,
  omit,
  keys,
  last,
  defaultsDeep,
} from "lodash";
import pluralize from "pluralize";
import { api } from "api-saga";
// import useRenderDebugger from "../use-renderer-debugger";
import { QueryContext } from "./QueryProvider";

// compare equality of everything except functions
const isEqual = (a, b) =>
  isEqualWith(a, b, (aa, bb) => {
    if (isFunction(aa) && isFunction(bb)) {
      return true;
    }

    return undefined;
  });

function isFile(val) {
  // don't execute this server-side
  if (typeof window === "undefined") {
    return false;
  }
  return val instanceof window.File;
}

function cloneDeep(data) {
  return cloneDeepWith(data, (val) => {
    // if the value is a file, return the file
    if (isFile(val)) {
      return val;
    }

    // otherwise returning undefined will result in default cloneDeep behavior
    return undefined;
  });
}

function hasPages(data, key) {
  return (
    has(data, "data") && key === "meta" && get(data, "meta.paginationParams")
  );
}

function ignorePath(data, key) {
  return (
    (has(data, "id") &&
      has(data, "type") &&
      includes(
        ["links", "onCreate", "onDestroy", "mapToApi", "writeOnly"],
        key
      )) ||
    hasPages(data, key)
  );
}

function cleanFormData(data, coll, addr = []) {
  if (!coll) {
    coll = {};
  }

  if (isObject(data) || isArray(data)) {
    each(data, (val, key) => {
      const nextAddr = [...addr, key];
      if (!ignorePath(data, key)) {
        cleanFormData(val, coll, nextAddr);
      }
    });
  } else if (!isFunction(data)) {
    set(coll, addr, data);
  }

  return coll;
}

function deepReject(lastParams, cursor, addr = []) {
  if (isObject(cursor)) {
    each(cursor, (val, key) => {
      if (key === "paginate") {
        set(lastParams, [...addr, key], val);
      } else {
        const nextAddr = [...addr, key];
        deepReject(lastParams, val, nextAddr);
      }
    });
  } else {
    unset(lastParams, addr);
  }

  return lastParams;
}

function extendQuery(
  getQuery,
  addr,
  updateQuery,
  parentId,
  omitParams,
  params
) {
  const coll = getQuery();
  const paramsPath = reject(addr, (s) => s === "data" || isNumber(s));
  if (parentId) {
    paramsPath.push("extend", parentId);
  }
  const lastParams = cloneDeep(get(coll, paramsPath));
  const mergeFn = omitParams ? deepReject : merge;
  const nextParams = mergeFn(lastParams, params);
  const nextQuery = cloneDeep(coll);

  set(nextQuery, paramsPath, nextParams);
  updateQuery(nextQuery);
}

function addPageActions(data, getQuery, updateQuery, coll, key, addr) {
  if (!hasPages(data, key)) {
    return;
  }

  const parentId = get(coll, [...addr.slice(0, -2), "id"]);

  const { number: firstPage, size: pageSize } = get(
    data,
    ["meta", "paginationParams", "first"],
    {}
  );
  const { number: prevPage } = get(
    data,
    ["meta", "paginationParams", "prev"],
    {}
  );
  const { number: totalPages } = get(
    data,
    ["meta", "paginationParams", "last"],
    {}
  );
  const recordCount = get(data, ["meta", "recordCount"], 0);
  const { sort, filter } = get(data, "meta", {});

  const selectPage = (number) => {
    return extendQuery(getQuery, addr, updateQuery, parentId, false, {
      paginate: { number },
    });
  };

  const handleSort = (sortValues, omitParams = false) =>
    extendQuery(getQuery, addr, updateQuery, parentId, omitParams, {
      paginate: { number: 1 },
      sort: sortValues,
    });

  const handleFilter = (filterValues, omitParams = false) =>
    extendQuery(getQuery, addr, updateQuery, parentId, omitParams, {
      paginate: { number: 1 },
      filter: filterValues,
    });

  const currentPage = prevPage ? prevPage + 1 : firstPage;

  set(coll, [...addr, "sort"], sort);
  set(coll, [...addr, "filter"], filter);
  set(coll, [...addr, "handleSort"], handleSort);
  set(coll, [...addr, "handleFilter"], handleFilter);
  set(coll, [...addr, "pagination"], {
    currentPage,
    totalPages,
    recordCount,
    selectPage,
    pageSize,
  });
}

function extendData(data, getQuery, updateQuery, coll, addr = []) {
  if (!coll) {
    coll = {};
  }

  if (isObject(data) || isArray(data)) {
    each(data, (val, key) => {
      const nextAddr = [...addr, key];
      addPageActions(data, getQuery, updateQuery, coll, key, addr);
      extendData(val, getQuery, updateQuery, coll, nextAddr);
    });
  } else {
    set(coll, addr, data);
  }

  return coll;
}

function mapQuery(data, coll, addr = []) {
  if (!coll) {
    coll = {};
  }

  if (isObject(data) || isArray(data)) {
    each(data, (val, key) => {
      const nextAddr = [...addr, key];
      const writeOnly = get(data, [...addr, key, "writeOnly"]);
      if (!writeOnly) {
        mapQuery(val, coll, nextAddr);
      }
    });
  } else if (!isFunction(data)) {
    set(coll, addr, data);
  }

  return coll;
}

function runNodeMutations(
  mapToApi,
  readOnly,
  writeOnly,
  node,
  query,
  queryAddr,
  key
) {
  // make sure the type + ID are passed in if this item was not loaded from API
  if (writeOnly) {
    node.id = get(query, [...queryAddr, "id"]);
  }

  // make sure the passed type is always accurate
  node.type = pluralize.plural(key);

  if (mapToApi) {
    node = mapToApi(node);
  }

  if (isArray(readOnly)) {
    node.attributes = omit(node.attributes, readOnly);
  }

  return node;
}

function dataAddrToQueryAddr(addr) {
  const queryAddr = [];

  for (let i = 0; i < addr.length; i += 1) {
    if (isNumber(addr[i]) && addr[i - 1] === "data") {
      queryAddr.pop();
    } else {
      queryAddr.push(addr[i]);
    }
  }

  return queryAddr;
}

function isIterable(obj) {
  return !isFile(obj) && (isArray(obj) || isObject(obj));

  // checks for null and undefined
  // if (obj == null) {
  // return false;
  // }
  // return typeof obj[Symbol.iterator] === 'function';
}

// in order for the form state diff to be effective, we need to account for any
// re-ordering of nested entity arrays
function transposeInitialValues({
  initialValues,
  currentValues,
  cursor,
  coll = {},
  addr = [],
}) {
  if (isIterable(cursor)) {
    each(cursor, (val, key) => {
      const nextAddr = [...addr, key];

      if (key === "data" && isArray(val)) {
        const newIdOrder = reduce(
          val,
          (memo, { id }, idx) => {
            memo[id] = idx;
            return memo;
          },
          {}
        );

        const oldDataArray = get(initialValues, nextAddr);
        const reSortedOldDataArray = cloneDeep(
          reduce(
            oldDataArray,
            (memo, item) => {
              if (newIdOrder[item.id] !== undefined) {
                memo[newIdOrder[item.id]] = item;
              }
              return memo;
            },
            []
          )
        );

        // important: make sure to update array with new value order before
        // recursing through data
        set(coll, nextAddr, reSortedOldDataArray);
        set(initialValues, nextAddr, reSortedOldDataArray);
      }

      transposeInitialValues({
        initialValues,
        currentValues,
        cursor: val,
        coll,
        addr: nextAddr,
      });
    });
  } else {
    const oldVal = get(initialValues, addr);
    set(coll, addr, oldVal);
  }

  return coll;
}

function diffFormState({
  initialValues,
  currentValues,
  cursor,
  coll = {},
  addr = [],
}) {
  if (isIterable(cursor)) {
    each(cursor, (val, key) => {
      const nextAddr = [...addr, key];

      diffFormState({
        initialValues,
        currentValues,
        cursor: val,
        coll,
        addr: nextAddr,
      });
    });
  } else if (!isFunction(cursor)) {
    const oldVal = get(initialValues, addr);
    if (!isEqual(cursor, oldVal)) {
      // if changed item exists within an array that is not an array of resources,
      // then make sure the entire array is sent back to the API
      const immediateParent = get(initialValues, addr.slice(0, -1));
      const immediateParentAddr = addr.slice(0, -1);
      const immediateParentName = last(immediateParentAddr);

      if (isArray(immediateParent) && immediateParentName !== "data") {
        set(coll, immediateParentAddr, immediateParent);
      }

      set(coll, addr, cursor);

      // backtrack up the chain and add all relevent parent ids
      while (addr.length) {
        addr.pop();

        const parent = get(currentValues, addr);

        // account for missing 'type' param in initial create data
        if (
          has(parent, "id") &&
          has(parent, "attributes") &&
          !has(parent, "type")
        ) {
          set(parent, "type", pluralize.plural(last(addr)));
        }

        if (has(parent, "id") && has(parent, "type")) {
          const { id, type } = parent;

          if (!get(coll, [...addr, "id"])) {
            set(coll, [...addr, "id"], id);
          }
          if (!get(coll, [...addr, "type"])) {
            set(coll, [...addr, "type"], type);
          }
        }

        if (has(parent, "data") && isArray(parent.data)) {
          const cursorArr = get(coll, [...addr, "data"]);
          if (cursorArr) {
            for (let i = 0; i < cursorArr.length; i += 1) {
              const item = get(currentValues, [...addr, "data", i]);
              if (item && has(item, "id") && has(item, "type")) {
                set(coll, [...addr, "data", i, "id"], item.id);
                set(coll, [...addr, "data", i, "type"], item.type);
              }
            }
          }
        }
      }
    }
  }

  return coll;
}

function mapApiData(query, data, coll, addr = []) {
  if (!coll) {
    coll = {};
  }

  if (!(data instanceof window.File) && (isObject(data) || isArray(data))) {
    each(data, (val, key) => {
      const nextAddr = [...addr, key];
      let nextVal = val;
      const queryAddr = dataAddrToQueryAddr([...addr, key]);
      const readOnly = get(query, [...queryAddr, "readOnly"]);

      if (readOnly !== true) {
        const mapToApi = get(query, [...queryAddr, "mapToApi"]);
        const writeOnly = get(query, [...queryAddr, "writeOnly"]);

        if (key !== "data" && !isNumber(key) && (mapToApi || readOnly)) {
          const dataSet = get(nextVal, "data");

          if (isArray(dataSet)) {
            nextVal.data = dataSet.map((item) =>
              runNodeMutations(
                mapToApi,
                readOnly,
                writeOnly,
                item,
                query,
                queryAddr,
                key
              )
            );
          } else {
            nextVal = runNodeMutations(
              mapToApi,
              readOnly,
              writeOnly,
              nextVal,
              query,
              queryAddr,
              key
            );
          }
        }

        mapApiData(query, nextVal, coll, nextAddr);
      }
    });
  } else {
    set(coll, addr, data);
  }

  return coll;
}

function runCreateDestroyCallbacks({
  query,
  destroyed,
  tempIds,
  values,
  path = [],
  createColl = {},
  destroyColl = {},
}) {
  if (isObject(query) || isArray(query)) {
    each(query, (fn, key) => {
      if (tempIds[query.id]) {
        if (!createColl[query.id]) {
          createColl[tempIds[query.id]] = {
            tempId: query.id,
            id: tempIds[query.id],
            type: pluralize.plural(last(path)),
          };
        }

        if (key === "onCreate") {
          fn({ tempId: query.id, id: tempIds[query.id] });
          createColl[tempIds[query.id]].fn = fn;
        }
      }

      if (find(destroyed, { id: query.id })) {
        if (!destroyColl[query.id]) {
          destroyColl[query.id] = {
            id: query.id,
            type: pluralize.plural(last(path)),
          };
        }

        if (key === "onDestroy") {
          fn({ id: query.id });
          destroyColl[query.id] = fn;
        }
      }

      runCreateDestroyCallbacks({
        query: fn,
        destroyed,
        tempIds,
        values,
        createColl,
        destroyColl,
        path: [...path, key],
      });
    });
  }

  return { createColl, destroyColl };
}

function replaceTempIds({ tempIds, values }) {
  if (has(values, "id") && has(tempIds, values.id)) {
    values.id = tempIds[values.id];
  }

  if (isObject(values) || isArray(values)) {
    each(values, (node) => {
      replaceTempIds({ tempIds, values: node });
    });
  }
}

function runUpdateCallbacks({
  query,
  changed,
  values,
  prevValues,
  globalOnUpdate,
  globalOnCreate,
  tempIds,
  path = [],
}) {
  if (isObject(changed) || isArray(changed)) {
    each(changed, (node, key) => {
      const nextPath = [...path, key];
      if (has(node, "id") && has(node, "type")) {
        const queryAddr = dataAddrToQueryAddr(nextPath);
        const onUpdate = get(query, [...queryAddr, "onUpdate"]);
        const nextValue = get(values, nextPath);
        const lastValue = get(prevValues, nextPath);
        onUpdate?.({
          nextValue,
          lastValue,
          change: pick(node, ["attributes"]),
        });

        if (includes(objValues(tempIds), node.id)) {
          globalOnCreate?.(node);
        } else {
          globalOnUpdate?.(nextValue);
        }
      }

      runUpdateCallbacks({
        query,
        changed: node,
        values,
        prevValues,
        tempIds,
        globalOnCreate,
        globalOnUpdate,
        path: nextPath,
      });
    });
  }
}

const useLazyState = (props) => {
  const dest = useRef({});
  keys(props).forEach((key) => {
    Object.defineProperty(dest.current, key, {
      get: () => props[key],
      enumerable: true,
      configurable: true,
    });
  });
  return dest.current;
};

function removeDestroyed(data, destroyed) {
  if (isEmpty(destroyed)) {
    return;
  }

  each(destroyed, ({ path }) => {
    const isArrayItem = /]$/.test(path);
    if (isArrayItem) {
      const arr = get(data, `${path}`.replace(/\[[^]]*\]$/, ""));
      const idx = parseInt(path.match(/\[([^]]*)\]$/)[1], 10);
      arr.splice(idx, 1);
    } else {
      unset(data, path);
    }
  });
}

const initialLoader = async (
  client,
  query,
  ns,
  readEndpoint,
  readMethod = "get"
) => {
  const readKey = /get/i.test(readMethod) ? "params" : "data";
  let extendedQueryData = mapQuery(query);
  if (isEmpty(extendedQueryData)) {
    return new Promise((res) => {
      res({});
    });
  }

  const resp = await client.request({
    method: readMethod,
    url: readEndpoint,
    [readKey]: {
      data: {
        data: extendedQueryData,
      },
    },
  });

  return extendData(
    resp.data,
    () => extendedQueryData,
    (val) => { extendedQueryData = val; }
  );
};


function suspend(promise) {
  let status = "pending";
  let result;
  const suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );

  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
      throw result;
    },
  };
}

function fetchInitialLoad(...args) {
  const promise = initialLoader(...args);
  return suspend(promise);
}

export function useQuery({
  ns,
  query,
  userType,
  initialData,
  readEndpoint,
  writeEndpoint,
  readMethod = "get",
  onSubmitError,
  onReadLoad,
  onCreate,
  onDestroy,
  onUpdate,
}) {
  const [data, setData] = useState(initialData);
  const [extendedQuery, setExtendedQuery] = useState(query);
  const readKey = /get/i.test(readMethod) ? "params" : "data";
  const [loading, setLoading] = useState(false);
  const queryData = mapQuery(query);
  const { request, requestState: loadState = {} } = api[readMethod](
    readEndpoint,
    {
      ns: `${ns}-read`,
      [readKey]: { data: { data: queryData } },
      userType,
    }
  );
  const { loading: fetching } = loadState;
  const { request: submit, requestState: submitState = {} } = api.patch(
    writeEndpoint,
    { ns: `${ns}-write`, userType }
  );
  const { loading: saving, status: submitStatus } = submitState;
  const submitProps = useMemo(
    () => ({
      submit,
      saving,
      submitStatus,
    }),
    []
  );

  const reload = useCallback(
    async (q) => {
      const extendedQueryData = mapQuery(q || extendedQuery);
      if (!isEmpty(extendedQueryData)) {
        await request({ [readKey]: { data: { data: extendedQueryData } } });
      }
    },
    [extendedQuery, readKey, request, onReadLoad]
  );

  const updateQuery = useCallback(
    (nextQuery) => {
      setExtendedQuery(nextQuery);
      reload(nextQuery);
    },
    [reload, setExtendedQuery]
  );

  let initialValues = cleanFormData(query, data);
  const [extendedData, setExtendedData] = useState(
    extendData(data, () => extendedQuery, updateQuery)
  );

  useEffect(() => {
    if (fetching) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [fetching]);

  // track changes to query and reload
  const queryRef = useRef(query);
  useEffect(() => {
    if (!isEqual(queryRef.current, query)) {
      queryRef.current = query;
      updateQuery(query);
    }
  }, [query]);

  useEffect(() => {
    if (fetching) {
      return;
    }

    const nextData = get(loadState, "data", initialData);
    if (!isEqual(data, nextData)) {
      const nextExtendedData = extendData(
        nextData,
        () => extendedQuery,
        updateQuery
      );

      setData(nextData);
      setExtendedData(nextExtendedData);
    }
  }, [initialData, loadState.data, fetching, data]);

  // trigger load callback whenever data has changed
  useEffect(() => {
    onReadLoad?.();
  }, [extendedData]);

  // initialize form data following initial load
  useEffect(() => {
    if (data) {
      const nextInitialValues = cleanFormData(query, data);
      if (!isEqual(initialValues, nextInitialValues)) {
        initialValues = nextInitialValues;
      }
    }
  }, [data]);

  useEffect(() => {
    const emptyFormValues = reduce(
      query,
      (memo, value, key) => {
        if (has(value, "id")) {
          memo[key] = pick(value, "id");
        }
        return memo;
      },
      {}
    );
    initialValues = defaultsDeep(initialData, emptyFormValues);
  }, []);

  const handleSubmit = useCallback(
    async (
      body,
      // eslint-disable-next-line no-unused-vars
      diff,
      {
        getState = () => ({
          values: {},
        }),
      } = {},
    ) => {
      try {
        const currentValues = cloneDeep(body);
        const initialValuesCopy = cloneDeep(initialValues);
        const transposedInitialValues = transposeInitialValues({
          currentValues,
          initialValues: initialValuesCopy,
          cursor: currentValues,
        });

        const requestBody = diffFormState({
          initialValues: transposedInitialValues,
          currentValues,
          cursor: currentValues,
        });
        const nextData = mapApiData(query, requestBody);

        const resp = await submit({ data: { data: { data: nextData } } });
        if (resp.errors) {
          onSubmitError?.(resp);
          return resp.errors;
        }

        const {
          // eslint-disable-next-line camelcase
          data: { changed, destroyed, temp_ids },
        } = resp;

        const lastState = getState().values;

        // merge new values into current state
        const nextState = mergeWith(lastState, changed, (objVal, srcVal) => {
          if (srcVal === null) {
            return objVal;
          }

          return undefined;
        });

        removeDestroyed(nextState, destroyed);

        runCreateDestroyCallbacks({
          query,
          destroyed,
          tempIds: temp_ids,
          values: nextState,
          onCreate,
          onDestroy,
        });

        each(destroyed, ({ id, type }) => {
          onDestroy?.({ id, type });
        });

        replaceTempIds({ tempIds: temp_ids, values: nextState });

        runUpdateCallbacks({
          query,
          changed,
          tempIds: temp_ids,
          values: nextState,
          prevValues: lastState,
          globalOnUpdate: onUpdate,
          globalOnCreate: onCreate,
        });

        initialValues = nextState;
      } catch (err) {
        onSubmitError?.(err);
        return err;
      }
      return undefined;
    },
    []
  );

  return useLazyState({
    reload,
    handleSubmit,
    initialValues,
    ...loadState,
    loading,
    ...submitProps,
    data: extendedData,
  });
}

// Error boundaries currently have to be classes.
class ErrorBoundary extends React.Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;

    if (hasError) {
      return fallback;
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  fallback: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
};

const Results = ({ Success, ns, loader, ...props }) => {
  const { readEndpoint, writeEndpoint } = useContext(QueryContext);

  const initialData = loader.read();

  const { handleSubmit, initialValues, ...renderProps } = useQuery({
    initialData,
    readEndpoint,
    writeEndpoint,
    ns,
    ...props,
  });

  useEffect(() => {
    props.onReadLoad?.();
  }, []);

  const content = (
    <Form
      initialValues={initialValues}
      initialValuesEqual={isEqual}
      subscription={{
        submitting: true,
        pristine: true,
        invalid: true,
        dirtyFields: true
      }}
      mutators={{ ...arrayMutators }}
      onSubmit={handleSubmit}
      render={(formProps) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const lazyProps = useLazyState({
          save: ({ diff = false } = {}) =>
            handleSubmit(
              formProps.form.getState().values,
              formProps.form,
              diff
            ),
          ...renderProps,
          ...formProps,
        });

        return <Success {...lazyProps} />;
      }}
    />
  );

  return content;
};

Results.propTypes = {
  Success: PropTypes.func,
  onReadLoad: PropTypes.func,
  loader: PropTypes.object,
  ns: PropTypes.string,
};

Results.defaultProps = {
  Success: ()=>{},
  onReadLoad: undefined,
  loader: {},
  ns: undefined,
};

const Query = ({ render: { Success, Error, Loading }, ...props }) => {
  const [ns] = useState(uuid());
  const { readEndpoint, clients } = useContext(QueryContext);
  const client = clients[props.userType];
  const [loader] = useState(
    fetchInitialLoad(client, props.query, ns, readEndpoint, props.readMethod)
  );

  return (
    <ErrorBoundary fallback={<Error />}>
      <Suspense fallback={<Loading />}>
        <Results {...props} ns={ns} Success={Success} loader={loader} />
      </Suspense>
    </ErrorBoundary>
  );
};

Query.propTypes = {
  query: PropTypes.object.isRequired,
  render: PropTypes.shape({
    Success: PropTypes.func.isRequired,
    Error: PropTypes.func.isRequired,
    Loading: PropTypes.func.isRequired,
  }).isRequired,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDestroy: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitError: PropTypes.func,
  onReadLoad: PropTypes.func,
  readMethod: PropTypes.string,
  userType: PropTypes.string,
};

Query.defaultProps = {
  onSubmitSuccess: undefined,
  onSubmitError: undefined,
  onReadLoad: undefined,
  onCreate: undefined,
  onUpdate: undefined,
  onDestroy: undefined,
  readMethod: "get",
  userType: undefined,
};

export default Query;

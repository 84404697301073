import dynamic from "next/dynamic";

const ToastContainer = dynamic(
  () => import("../../components/ToastContainer"),
  {
    suspense: true
  }
);

export default ToastContainer;

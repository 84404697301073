export default function currentUrl(ctx) {
  if (ctx?.req) {
    return ctx.req?.url;
  }

  if (typeof window !== "undefined") {
    return `${window.location.pathname}${window.location.search}`;
  }

  return null;
}

import { HYDRATE, createWrapper } from "next-redux-wrapper";
import { combineReducers, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import { apiSaga, apiReducer as queryApi } from "api-saga";
import {
  nextReduxCookieMiddleware,
  wrapMakeStore,
} from "next-redux-cookie-wrapper";
import thunkMiddleware from "redux-thunk";
import auth from "./auth";
import location from "./location";

// init redux saga
// https://redux-saga.js.org/docs/advanced/RootSaga/
const sagaMiddleware = createSagaMiddleware();
const sagas = [apiSaga];
function* rootSaga({ clients }) {
  yield all(sagas.map((saga) => saga(clients)));
}

// define redux middleware here
// https://redux.js.org/tutorials/fundamentals/part-4-store#middleware
const appMiddleware = [
  thunkMiddleware,
  sagaMiddleware,
  nextReduxCookieMiddleware({
    secure: true,
    path: "/",
    subtrees: ["auth", "location"],
  }),
];

// add redux modules here
// https://redux.js.org/api/combinereducers
const combinedReducers = combineReducers({ auth, queryApi, location });

// if hydrating from SSR render state, reconsile with client initial store state
// see https://github.com/vercel/next.js/blob/canary/examples/with-redux-wrapper/store/store.js
const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };

    return nextState;
  }

  return combinedReducers(state, action);
};

// use redux devtools if using local dev environment and not SSR render
const bindMiddleware = (middleware) => {
  if (true) {
    // if (process.env.NODE_ENV !== "production") {
    /* eslint-disable global-require, import/no-extraneous-dependencies */
    const { composeWithDevTools } = require("redux-devtools-extension");
    /* eslint-enable global-require, import/no-extraneous-dependencies */
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

// __saga task accessed in pages/_app.js to be initialized with api clients
function bindSagasToStore(reduxStore) {
  // eslint-disable-next-line no-param-reassign
  reduxStore.__saga = ({ clients }) =>
    sagaMiddleware.run(rootSaga, { clients });
  return reduxStore;
}

const initStore = wrapMakeStore(() => {
  // create the redux store
  const store = createStore(rootReducer, bindMiddleware(appMiddleware));

  bindSagasToStore(store);

  return store;
});

// this wrapper should only be used to wrap the pages/_app.js container.
// the purpose of wrapping the _app.js container this way is so that we have
// access to the redux store from within the App.getInitialProps and
// App.getServerSideProps static methods.
export default createWrapper(initStore, { debug: false });

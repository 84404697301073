import rebassPresetTheme from "@rebass/preset";
import { defaultsDeep } from "lodash";
import { theme as genTheme } from "@refract-ui/core";
import baseStyles from "theme/base";

export const refractTheme = genTheme({
  globalStyles: baseStyles,
  blockElements: {
    container: {
      mt: 0,
      mb: 0,
    },
  },
  fontStacks: {
    sans: [
      "HarmoniaSansProCyr",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ],
    serif: ["GT-Alpina-Standard-Medium"],
    serifItalic: ["GT-Alpina-Standard-Medium-Italic"],
  },
  fontVariants: ({ defaults }) =>
    defaultsDeep(
      {
        anchor: {
          weight: 600,
        },
        heading: {
          letterSpacing: "0",
        },
        quote: {
          weight: 600,
          stack: [defaults.sans],
        },
        display: {
          weight: 300,
        },
        default: {
          weight: 600,
        },
        code: {
          weight: 600,
        },
        button: {
          weight: 600,
        },
        link: {
          weight: 600,
        },
      },
      defaults
    ),
  colors: ({ defaults }) => ({
    ...defaults,
    black: "#192617",
    orange: "#FF7817",
    yellow: "#F5B40C",
    green: "#54C052",
    red: "#C83E35",
    purple: "#4D417A",
    teal: "#357778",
    blue: "#B6EAEB",
    gray: "#8F978C",
    white: "#FFFFFF",
  }),
  themeColors: ({ theme: { colors } }) => ({
    primary: colors.orange,
    primaryPointFiveOpacity: "rgba(255,120,23, 0.5)",
    secondary: colors.gray,
    dark: colors.black,
    light: "#F8F7F7",
    subtleLight: "#F6F9F7",
    fg: colors.black,
    bg: colors.white,
    danger: colors.red,
    warning: colors.yellow,
    neutral: "#FFECD9",
    success: colors.green,
    black: "#000000",
    blue1: colors.blue,
    blue2: "#81D2DB",
    blue3: "#DAF0EB",
    blue4: "#EEF5F5",
    blue5: "#699B9C",
    brown: "#521900",
    lightBlue: "#D4EDED",
    mediumBlue: "#B9EBEC",
    lightGreen1: "#F2FFF0",
    lightGreen2: "#DCEADA",
    lightGreen3: "#FAFFFB",
    lightGreen4: "#EAFDE7",
    lightGrey: "#E0E3E0",
    lightGrey1: "#DADADA",
    mediumGrey: "#3F493D",
    mint: "#F0FAF0",
    mediumMint: "#F0FFF0",
    darkGrey: "#162516",
    darkGreen1: colors.black,
    darkGreen1Point1Opacity: "rgba(25, 38, 23, 0.1)",
    darkGreen1Point2Opacity: "rgba(25, 38, 23, 0.2)",
    darkGreen1Point3Opacity: "rgba(25, 38, 23, 0.3)",
    darkGreen1Point4Opacity: "rgba(25, 38, 23, 0.4)",
    darkGreen2: "#40493D",
    darkGreen3: "#8F978C",
    darkGreen4: "#6D726B",
    orange1: colors.orange,
    orange2: "#C84A21",
    orange3: "#F3AD84",
    orange4: "#FBDEC9",
    goodfriendDark: "#27272F",
    white: "#fff",
    hoverSecondary: "#CCD1CC",
    hoverSecondaryPoint2Opacity: "rgba(204, 209, 204, 0.2)",
    borderColor: "#EEF5F5",
    pendingColor: colors.teal,
    pendingBackground: "#E3FAF5",
    confirmColor: "#E0640A",
    confirmBackground: "#FEEFE4",
    successPillBackground: "#D8F6DB",
    successPillColor: "#307637",
    warningPillBackground: "#FFEDCA",
    warningPillColor: "#855A08",
    errorPillBackground: "#FBC5C2",
    errorPillColor: "#C2342A",
    neutralPillBackground: "#D8D3EC",
    neutralPillColor: colors.purple,
    gray80: "#343A3F",
    reschedulingPillBackground: "#E9F0FF",
    reschedulingPillColor: "#2E5FBE",
    disabled: "#D2D5D1",
  }),
  darkColors: {
    secondary: "#8A8685",
  },
  spacing: ["0.25rem", "0.5rem", "1rem", "1.5rem", "2.5rem", "5rem"],
});

// extract select vars from custom theme to apply to rebass components
const { primary, secondary, danger, dark, light, info, success, warning } = refractTheme;

// map vars to rebass theme object structure
const customThemeMapping = {
  colors: {
    primary,
    secondary,
    danger,
    dark,
    light,
    info,
    success,
    warning,
  },
  opacityTransition: "opacity 0.25s ease-in-out",
  fontFamily: "HarmoniaSansProCyr",
  fontSize: (number = 1) => {
    return `${rebassPresetTheme.fontSizes[number] / 16}rem`;
  },
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 600,
  },
  settings: {
    shadows: { boxShadow: "0 0.125rem 0.375rem 0 rgba(138,168,181,0.12)" },
    sizes: { maxContentWidth: 1024, maxLandingWidth: 1220 },
  },
};
export const theme = {
  ...rebassPresetTheme,
  ...customThemeMapping,
  ...refractTheme,
};

import { set } from "object-path-immutable";
import { createReducer } from "../utils";

export const UPDATE_PHYSICAL_ADDRESS = "UPDATE_PHYSICAL_ADDRESS";
export const UPDATE_IP_ADDRESS = "UPDATE_IP_ADDRESS";

const initialState = {
  physicalAddress: undefined,
  ipAddress: undefined,
};

export default createReducer(initialState, {
  [UPDATE_PHYSICAL_ADDRESS]: (state, { payload }) =>
    set(state, "physicalAddress", payload),
  [UPDATE_IP_ADDRESS]: (state, { payload }) => set(state, "ipAddress", payload),
});

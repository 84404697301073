export var prefix = '@@dtaClient:';
export var AUTH_INITIALIZED = "".concat(prefix, "AUTH_INITIALIZED");
export var UPDATE_AUTH_CREDS = "".concat(prefix, "UPDATE_AUTH_CREDS");
export var CLEAR_AUTH_CREDS = "".concat(prefix, "CLEAR_AUTH_CREDS");
export var INVALID_CREDS = "".concat(prefix, "INVALID_CREDS");
export var UPDATE_USER = "".concat(prefix, "UPDATE_USER");
export var VALIDATE_TOKEN_START = "".concat(prefix, "VALIDATE_TOKEN_START");
export var VALIDATE_TOKEN_SUCCESS = "".concat(prefix, "VALIDATE_TOKEN_SUCCESS");
export var VALIDATE_TOKEN_ERROR = "".concat(prefix, "VALIDATE_TOKEN_ERROR");
export var EMAIL_SIGNUP_START = "".concat(prefix, "EMAIL_SIGNUP_START");
export var EMAIL_SIGNUP_SUCCESS = "".concat(prefix, "EMAIL_SIGNUP_SUCCESS");
export var EMAIL_SIGNUP_ERROR = "".concat(prefix, "EMAIL_SIGNUP_ERROR");
export var EMAIL_SIGNUP_CONFIRMATION_SUCCESS = "".concat(prefix, "EMAIL_SIGNUP_CONFIRMATION_SUCCESS");
export var EMAIL_SIGNUP_CONFIRMATION_ERROR = "".concat(prefix, "EMAIL_SIGNUP_CONFIRMATION_ERROR");
export var EMAIL_SIGNIN_START = "".concat(prefix, "EMAIL_SIGNIN_START");
export var EMAIL_SIGNIN_SUCCESS = "".concat(prefix, "EMAIL_SIGNIN_SUCCESS");
export var EMAIL_SIGNIN_ERROR = "".concat(prefix, "EMAIL_SIGNIN_ERROR");
export var SIGN_OUT_START = "".concat(prefix, "SIGN_OUT_START");
export var SIGN_OUT_SUCCESS = "".concat(prefix, "SIGN_OUT_SUCCESS");
export var SIGN_OUT_ERROR = "".concat(prefix, "SIGN_OUT_ERROR");
export var PASSWORD_RESET_REQUEST_START = "".concat(prefix, "PASSWORD_RESET_REQUEST_START");
export var PASSWORD_RESET_REQUEST_SUCCESS = "".concat(prefix, "PASSWORD_RESET_REQUEST_SUCCESS");
export var PASSWORD_RESET_REQUEST_ERROR = "".concat(prefix, "PASSWORD_RESET_REQUEST_ERROR");
export var PASSWORD_UPDATE_START = "".concat(prefix, "PASSWORD_UPDATE_START");
export var PASSWORD_UPDATE_SUCCESS = "".concat(prefix, "PASSWORD_UPDATE_SUCCESS");
export var PASSWORD_UPDATE_ERROR = "".concat(prefix, "PASSWORD_UPDATE_ERROR");
export var PASSWORD_RESET_CONFIRMATION_SUCCESS = "".concat(prefix, "PASSWORD_RESET_CONFIRMATION_SUCCESS");
export var PASSWORD_RESET_CONFIRMATION_ERROR = "".concat(prefix, "PASSWORD_RESET_CONFIRMATION_ERROR");
export var OAUTH_SIGNUP_SUCCESS = "".concat(prefix, "OAUTH_SIGNUP_SUCCESS");
export var OAUTH_SIGNUP_ERROR = "".concat(prefix, "OAUTH_SIGNUP_ERROR");
export var OAUTH_SIGNIN_START = "".concat(prefix, "OAUTH_SIGNIN_START");
export var OAUTH_SIGNIN_SUCCESS = "".concat(prefix, "OAUTH_SIGNIN_SUCCESS");
export var OAUTH_SIGNIN_ERROR = "".concat(prefix, "OAUTH_SIGNIN_ERROR");
export var PERSIST_THROUGH_REDIRECT = "".concat(prefix, "PERSIST_THROUGH_REDIRECT");
export var LOAD_AFTER_REDIRECT = "".concat(prefix, "LOAD_AFTER_REDIRECT");
/* istanbul ignore next */
export var facebook = {
  width: 580,
  height: 400
};
export var google = {
  width: 452,
  height: 633
};
export var github = {
  width: 1020,
  height: 618
};
export var linkedin = {
  width: 527,
  height: 582
};
export var twitter = {
  width: 495,
  height: 645
};
export var live = {
  width: 500,
  height: 560
};
export var yahoo = {
  width: 559,
  height: 519
};
import { camelCase, isEmpty, upperFirst } from "lodash";
import pluralize from "pluralize";

export default function dataLayerPush(payload) {
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push(payload);
}

const sanitizeServices = (services) => {
  const SERVICES = ["grooming", "training", "veterinary"];
  const filtered = SERVICES.filter((s) => {
    return services?.join(",").toLowerCase().includes(s);
  });
  return filtered?.length === SERVICES.length ? "all" : filtered?.join(",");
};

export function trackUserAccountUpdate({ change, lastValue }) {
  const userId = lastValue?.id;
  const accountType = lastValue?.type?.replace("Users", "");
  const changedValues = change?.attributes;
  if (!isEmpty(changedValues)) {
    dataLayerPush({
      event: "updateProfile",
      changedValues,
      userId,
      accountType,
    });
  }
}

export function trackResourceChange({ eventType, data: { id, type, attributes }, currentUser: { accountType, userID } }) {
  const resourceType = pluralize.singular(type);
  const event = `${eventType}${upperFirst(camelCase(resourceType))}`;

  const payload = {
    id,
    event,
    resourceType,
    attributes,
    accountType,
    userId: userID,
  };

  dataLayerPush(payload);
}

const getBookingTypeGA = (bookingType) => {
  if (bookingType === "instant") {
    return "book online";
  }
  if (bookingType === "complete") {
    return "request to book";
  }
  return "online coming soon";
};

export function getItemCategory(biz) {
  const serviceNames = biz?.include?.serviceTypes?.data?.map((item) => {
    return item?.attributes?.name;
  });
  return sanitizeServices(serviceNames);
}

export const getSnakeCaseCategory = (biz) => {
  const serviceNames = biz?.business_type?.split("_");
  return sanitizeServices(serviceNames);
};

const mapBookingStatus = (bookingStatus) => {
  const bs = parseInt(bookingStatus, 10);
  if (bs >= 5) {
    return "live";
  }
  if (bs >= 4) {
    return "complete_booking";
  }
  if (bs >= 2) {
    return "claimed_with_services";
  }
  if (bs >= 1) {
    return "claimed_without_services";
  }
  return "unclaimed";
};

const mapSnakeCaseBusiness = (biz) => {
  return {
    item_id: biz.id,
    item_name: biz.business_name,
    currency: "USD",
    item_category2: getBookingTypeGA(biz.booking_type),
    city: biz.url_locality,
    state: biz.url_region,
    booking_type: biz.booking_type?.concat(" booking"),
    booking_status: mapBookingStatus(biz.booking_status),
    quantity: 1,
  };
};

const mapDataLayerBusiness = (biz) => {
  if (biz?.business_name) {
    return mapSnakeCaseBusiness(biz);
  }
  const atts = biz?.attributes;
  return {
    item_id: biz?.id,
    item_name: atts?.businessName,
    currency: "USD",
    // item_list_name: serviceTypeFilter, //grooming, training, veterinary, all
    // item_category: serviceTypeFilter, //grooming, training, veterinary, all
    item_category2: getBookingTypeGA(atts?.bookingType),
    city: atts?.urlLocality,
    state: atts?.urlRegion,
    item_variant: `${atts?.urlLocality}-${atts?.urlRegion}`,
    booking_type: atts?.bookingType?.concat(" booking"),
    booking_status: mapBookingStatus(atts?.bookingStatus),
    quantity: 1,
  };
};

export const trackUserStatus = ({
  event,
  userType: accountType,
  userId,
  uid,
  oktaId,
  email,
  firstName,
  lastName,
  phoneNumber,
  image,
  hasOutstandingBalance,
  hasPendingBalance,
  signUpLocation,
}) =>
  dataLayerPush({
    event,
    accountType,
    oktaId,
    userId,
    signUpLocation,
    user: {
      provider: "okta",
      id: userId,
      uid,
      email,
      firstName,
      lastName,
      phoneNumber,
      image,
      hasOutstandingBalance,
      hasPendingBalance,
    },
  });

export function dataLayerViewItemList(bizList, serviceTypeFilter, source = "standard-search") {
  if (bizList == null) return;
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object
  dataLayerPush({
    event: "view_item_list",
    ecommerce: {
      items: bizList?.map((biz, index) => {
        return {
          ...mapSnakeCaseBusiness(biz, serviceTypeFilter),
          item_category: getSnakeCaseCategory(biz),
          item_category3: source,
          item_list_name: serviceTypeFilter,
          index,
        };
      }),
    },
  });
}

export function dataLayerSelectItem(biz, serviceTypeFilter, source = "standard-search") {
  if (biz == null) return;
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object
  dataLayerPush({
    event: "select_item",
    ecommerce: {
      items: [
        {
          ...mapSnakeCaseBusiness(biz, serviceTypeFilter),
          item_list_name: serviceTypeFilter,
          item_category: getSnakeCaseCategory(biz),
          item_category3: source,
        },
      ],
    },
  });
}

export function dataLayerViewItem(biz) {
  if (biz == null) return;

  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object
  dataLayerPush({
    event: "view_item",
    ecommerce: {
      items: [
        {
          ...mapDataLayerBusiness(biz),
          item_category: getItemCategory(biz),
        },
      ],
    },
  });
}

export function dataLayerAddToCart(businesses, selectedServiceType, price, source = "standard-search") {
  if (!businesses?.length) return;
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object
  dataLayerPush({
    event: "add_to_cart",
    ecommerce: {
      value: price,
      items: businesses.map((biz) => ({
        ...mapDataLayerBusiness(biz),
        item_category: sanitizeServices([selectedServiceType]),
        price,
        item_category3: source,
      })),
    },
  });
}

export function dataLayerBeginCheckout(businesses, selectedServiceType, price, source = "standard-search") {
  if (!businesses?.length) return;
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object
  dataLayerPush({
    event: "begin_checkout",
    value: price,
    ecommerce: {
      items: businesses.map((biz) => ({
        ...mapDataLayerBusiness(biz),
        item_category: sanitizeServices([selectedServiceType]),
        price,
        item_category3: source,
      })),
    },
  });
}

export function dataLayerPurchase(
  businesses,
  selectedServiceType,
  transaction_id,
  value = 0,
  serviceFee = null,
  ecommercePayload = {},
  source = "standard-search"
) {
  if (!businesses?.length) return;
  dataLayerPush({ ecommerce: null }); // Clear the previous ecommerce object
  dataLayerPush({
    event: "purchase",
    ecommerce: {
      ...ecommercePayload,
      value,
      transaction_id,
      shipping: serviceFee,
      items: businesses?.map((biz) => ({
        ...mapDataLayerBusiness(biz),
        item_category: sanitizeServices([selectedServiceType]),
        item_category3: source,
      })),
    },
  });
}

export function dataLayerGuidedSearchSubmit(clickText, resultCount, selectedCount) {
  dataLayerPush({
    event: "guided_search_cta_click",
    click_text: clickText,
    guided_search_results: resultCount,
    guided_search_selected: selectedCount,
  });
}

export function dataLayerVirtualPageView(pagePath, pageTitle, resultCount) {
  dataLayerPush({
    event: "virtual_pageview",
    page_path: pagePath,
    page_title: pageTitle,
    guided_search_results: resultCount,
  });
}

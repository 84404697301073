import { reduce, forEach, has, isNil, isObject, isArray } from "lodash";

export function isShrineData(val) {
  return (
    (has(val, "full") && has(val, "metadata")) ||
    (has(val, "id") && has(val, "metadata"))
  );
}

export function isFile(val) {
  // don't execute this server-side
  if (typeof window === "undefined") {
    return false;
  }
  return val instanceof window.File;
}

export function includesFiles(params) {
  if (isObject(params) && !isFile(params)) {
    const keys = Object.keys(params);
    for (let i = 0; i < keys.length; i += 1) {
      if (includesFiles(params[keys[i]])) {
        return true;
      }
    }
  }
  return isFile(params);
}

export function removeShrineData(params) {
  forEach(params, (val, key) => {
    if (isShrineData(val)) {
      delete params[key];
      return;
    }
    if (isObject(val) || isArray(val)) {
      removeShrineData(val);
    }
  });
  return params;
}

export function objectToFormData(obj, namespace, memo = []) {
  let formKey;

  Object.keys(obj).forEach((property) => {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File, use recursivity.
      if (obj[property] instanceof Date) {
        memo.push({
          formKey,
          value: obj[property].toISOString(),
          type: "string",
        });
      } else if (
        obj[property] &&
        typeof obj[property] === "object" &&
        !isFile(obj[property])
      ) {
        objectToFormData(obj[property], formKey, memo);
      } else {
        // if it's a string or a File object
        const type = isFile(obj[property]) ? "file" : "string";
        const value = isNil(obj[property]) ? "" : obj[property];
        memo.push({ formKey, value, type });
      }
    }
  });

  return memo;
}

export function multipartFileInterceptor(config) {
  if (config.data) {
    removeShrineData(config.data);
  }

  if (config.data && includesFiles(config.data)) {
    const data = objectToFormData(config.data);
    config.data = reduce(
      data,
      (fd, { formKey, value }) => {
        fd.append(formKey, value);
        return fd;
      },
      new FormData()
    );
  }

  return config;
}

import "styles/globals.css";
import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { END } from "redux-saga";
import { useStore } from "react-redux";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "@refract-ui/core";
import AppContextProvider from "context/AppContext";
import store from "store";
import initAuthClient from "lib/auth-client";
import GlobalScripts from "components/GlobalScripts";
import { theme } from "lib/consolidate-theme";
import { setLocationFromIP } from "hooks/use-current-location";
import IconLoader from "dynamic/IconLoader";
import ToastContainer from "dynamic/ToastContainer";
import { setUserIp } from "lib/find-location-by-ip";
import { DefaultSeo } from "next-seo";
import 'swiper/swiper-bundle.min.css';

function App({
  Component,
  pageProps,
  adminClient,
  vendorClient,
  customerClient,
}) {
  const reduxStore = useStore((state) => state);
  // useEffect(() => {
  //   if (
  //     globalThis?.location?.protocol &&
  //     globalThis?.location?.protocol !== "https:" &&
  //     globalThis?.location?.hostname !== "localhost"
  //   ) {
  //     globalThis.location.protocol = "https:";
  //   }
  // }, []);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <GlobalScripts />
      <AppContextProvider
        adminClient={adminClient}
        vendorClient={vendorClient}
        customerClient={customerClient}
        store={reduxStore}
      >
        <DefaultSeo
          title="GoodFriend"
          description="Find the best pet service providers, including locally-reviewed pet groomers and dog trainers, on GoodFriend"
          additionalLinkTags={[
            {
              rel: "shortcut icon",
              href: "/favicon.png",
            },
          ]}
        />
        <Suspense>
          <IconLoader />
        </Suspense>
        <Suspense>
          <ToastContainer />
        </Suspense>
        <Component {...pageProps} />
      </AppContextProvider>
    </ThemeProvider>
  );
}

//

App.getInitialProps = store.getInitialAppProps(
  (reduxStore) =>
    async ({ Component, ctx }) => {
      // wait for user session to be initialized + validated before rendering the
      // initial page

      const adminClient = initAuthClient({
        ctx,
        base: "admin_auth",
        userType: "admin",
        store: reduxStore,
      });

      const vendorClient = initAuthClient({
        ctx,
        base: "vendor_auth",
        userType: "vendor",
        store: reduxStore,
      });

      const customerClient = initAuthClient({
        ctx,
        base: "customer_auth",
        userType: "customer",
        store: reduxStore,
      });

      // wait for auth client to finish loading
      await Promise.all([
        adminClient.isInitialized,
        vendorClient.isInitialized,
        customerClient.isInitialized,
      ]);

      // wait for all page actions to dispatch
      const pageProps = {
        ...(Component.getInitialProps
          ? await Component.getInitialProps({ ...ctx, store: reduxStore })
          : {}),
      };

      // if on the server, wait for saga runner to finish
      if (ctx.req) {
        const ipAddress = await setUserIp(ctx.req, reduxStore);
        await setLocationFromIP(ipAddress, reduxStore);
        reduxStore.dispatch(END);

        const sagaRunner = reduxStore.__saga({
          clients: {
            admin: adminClient,
            vendor: vendorClient,
            customer: customerClient,
          },
        });

        await sagaRunner.toPromise();
      }

      return {
        adminClient,
        vendorClient,
        customerClient,
        pageProps,
      };
    }
);

App.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.shape({}),
  ]),
  pageProps: PropTypes.any,
  adminClient: PropTypes.any,
  vendorClient: PropTypes.any,
  customerClient: PropTypes.any,
  /* eslint-enable react/forbid-prop-types */
};

App.defaultProps = {
  Component: {},
  pageProps: {},
  adminClient: null,
  vendorClient: null,
  customerClient: null,
};

export default store.withRedux(App);

import axios from "axios";
import { compact, includes } from "lodash";
import publicIp from "public-ip";
import { UPDATE_IP_ADDRESS } from "store/location";
import { MAXMIND_ACCOUNT_NO, MAXMIND_LICENSE_NO } from "lib/constants";

const localAddresses = ["::1", "127.0.0.1", "localhost", "::ffff:127.0.0.1"];

export async function setUserIp(req, store) {
  let ipAddress = (
    req.headers["x-forwarded-for"] || req.connection.remoteAddress
  )?.split?.(",")?.[0];

  // check to see if we're on an internal network (i.e. local dev)
  if (includes(localAddresses, ipAddress)) {
    ipAddress = await publicIp.v4();
  }

  store.dispatch({ type: UPDATE_IP_ADDRESS, payload: ipAddress });

  return ipAddress;
}

export default async function findLocationByIp(ipAddress) {
  if (!ipAddress) {
    return {
      errorMessage: "no IP address",
    };
  }

  // fetch the user's address using ip2location lookup service
  try {
    const { data } = await axios.get(
      `https://geoip.maxmind.com/geoip/v2.1/city/${ipAddress}?pretty`,
      {
        auth: {
          username: MAXMIND_ACCOUNT_NO,
          password: MAXMIND_LICENSE_NO,
        },
        crossDomain: true,
      }
    );
    const payload = {
      cityName: data?.city?.names?.en,
      regionName: data?.subdivisions?.[0]?.iso_code,
      countryCode: data?.country?.iso_code,
      countryName: data?.country?.names?.en,
      latitude: data?.location?.latitude,
      longitude: data?.location?.longitude,
    };

    payload.ipAddress = ipAddress;

    payload.formatted_address = compact([
      payload.cityName,
      payload.regionName,
    ]).join(", ");

    return payload;
  } catch (error) {
    return {
      ipAddress,
      errorCode: error.code,
      errorMessage: error.message
        .replace(MAXMIND_ACCOUNT_NO, "demo")
        .replace(MAXMIND_LICENSE_NO, "demo"),
    };
  }
}

import { wrap as i, set } from "object-path-immutable";
import {
  AUTH_INITIALIZED,
  UPDATE_AUTH_CREDS,
  CLEAR_AUTH_CREDS,
  UPDATE_USER,
  EMAIL_SIGNUP_SUCCESS,
  EMAIL_SIGNUP_CONFIRMATION_SUCCESS,
  EMAIL_SIGNUP_CONFIRMATION_ERROR,
  EMAIL_SIGNIN_SUCCESS,
  PASSWORD_RESET_CONFIRMATION_SUCCESS,
  PASSWORD_RESET_CONFIRMATION_ERROR,
  LOAD_AFTER_REDIRECT,
  SIGN_OUT_START,
} from "devise-token-auth-client";
import dataLayerPush from "lib/data-layer-push";
import { isString } from "lodash";
import { createReducer } from "./utils";

const initialClientState = {
  loading: true,
  emailSignUpConfirmationSuccess: false,
  emailSignUpConfirmationError: false,
  passwordResetConfirmationSuccess: false,
  passwordResetConfirmationError: false,
  user: null,
};

const initialState = {
  vendor: { ...initialClientState },
  customer: { ...initialClientState },
};

export default createReducer(initialState, {
  [AUTH_INITIALIZED]: (state, { userType, payload }) =>
    i(state)
      .set(`${userType}.creds`, payload)
      .set(`${userType}.loading`, false)
      .value(),

  [UPDATE_AUTH_CREDS]: (state, { userType, payload }) =>
    set(state, `${userType}.creds`, payload),

  [CLEAR_AUTH_CREDS]: (state, { userType }) =>
    i(state)
      .set(`${userType}.creds`, null)
      .set(`${userType}.user`, null)
      .value(),

  [UPDATE_USER]: (state, { userType, payload }) =>
    set(state, `${userType}.user`, payload),

  [EMAIL_SIGNUP_CONFIRMATION_SUCCESS]: (state, { userType }) =>
    set(state, `${userType}.emailSignUpConfirmationSuccess`, true),

  [EMAIL_SIGNUP_CONFIRMATION_ERROR]: (state, { userType }) =>
    set(state, `${userType}.emailSignUpConfirmationError`, true),

  [PASSWORD_RESET_CONFIRMATION_SUCCESS]: (state, { userType }) =>
    set(state, `${userType}.passwordResetConfirmationSuccess`, true),

  [PASSWORD_RESET_CONFIRMATION_ERROR]: (state, { userType }) =>
    set(state, `${userType}.passwordResetConfirmationError`, true),

  [LOAD_AFTER_REDIRECT]: (_, { payload }) => {
    if (isString(payload)) {
      return JSON.parse(payload);
    }
    return payload;
  },

  [EMAIL_SIGNUP_SUCCESS]: (state, { userType }) => {
    const user = state[userType]?.user;
    dataLayerPush({
      user,
      userId: user.id,
      event: "new-account",
      accountType: userType,
    });

    return state;
  },

  [EMAIL_SIGNIN_SUCCESS]: (state, { userType }) => {
    const user = state[userType]?.user;
    dataLayerPush({
      user,
      userId: user.id,
      event: "login",
      accountType: userType,
    });

    return state;
  },

  [SIGN_OUT_START]: (state, { userType }) => {
    const user = state[userType]?.user;
    if (user) {
      dataLayerPush({
        user,
        userId: user.id,
        event: "logout",
        accountType: userType,
      });
    }

    return state;
  },
});

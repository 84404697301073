import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { isPlainObject } from "lodash";
import { QueryProvider } from "../lib/Query";
import initAuthClient from "../lib/auth-client";

const defaultValue = {
  vendorClient: null,
};

export const AppContext = React.createContext(defaultValue);

function AppContextProvider({
  adminClient: _adminClient,
  vendorClient: _vendorClient,
  customerClient: _customerClient,
  children,
  store,
}) {
  // if SSR then vendorClient will have been converted to a plain object for
  // state hydration. we must convert back into an Axios instance
  const clients = useMemo(() => {
    const admin = !isPlainObject(_adminClient)
      ? _adminClient
      : initAuthClient({
          base: "admin_auth",
          userType: "admin",
          store,
        });

    const vendor = !isPlainObject(_vendorClient)
      ? _vendorClient
      : initAuthClient({
          base: "vendor_auth",
          userType: "vendor",
          store,
        });

    const customer = !isPlainObject(_customerClient)
      ? _customerClient
      : initAuthClient({
          base: "customer_auth",
          userType: "customer",
          store,
        });

    if (!store.sagaRunning) {
      store.__saga({ clients: { admin, vendor, customer } });
      store.sagaRunning = true;
    }

    return { admin, vendor, customer };
  }, []);

  return (
    <QueryProvider
      store={store}
      clients={clients}
      reducerKey="queryApi"
      defaultUserType="customer"
    >
      <AppContext.Provider
        value={{
          adminClient: clients.admin,
          vendorClient: clients.vendor,
          customerClient: clients.customer,
        }}
      >
        {children}
      </AppContext.Provider>
    </QueryProvider>
  );
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  /* eslint-disable react/forbid-prop-types */
  adminClient: PropTypes.any.isRequired,
  vendorClient: PropTypes.any.isRequired,
  customerClient: PropTypes.any.isRequired,
  store: PropTypes.any.isRequired,
  /* eslint-enable react/forbid-prop-types */
};

export default AppContextProvider;

import dynamic from "next/dynamic";

const IconLoader = dynamic(
  () => import("../../components/IconLoader"),
  {
    suspense: true,
    ssr: true,
  }
);

export default IconLoader;
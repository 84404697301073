import React from "react";
import AllIcons from "components/icons/icon-symbols";

const IconLoader = () => {
  return (
    <span
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: AllIcons }}
    />
  );
};

export default IconLoader;
